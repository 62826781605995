import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function integrationsName(integration) {
  const Text = text[getFromLocalStorage("lang")];
  if (Text.integrations[integration]) {
    return Text.integrations[integration];
  } else {
    return integration;
  }
}
