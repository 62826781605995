import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function paymentTypeName(type) {
  const Text = text[getFromLocalStorage("lang")];
  if (Text.types[type]) {
    return Text.types[type];
  } else {
    return type;
  }
}
