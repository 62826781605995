import getBrowserFingerprint from 'get-browser-fingerprint';
import ls from 'localstorage-slim';
import CryptoJS from 'crypto-js';

let fingerprint;
ls.config.encrypt = true;
try {
    fingerprint = getBrowserFingerprint();
    if(fingerprint){
        ls.config.secret = fingerprint;
    }else{
        ls.config.secret = 6078564;
    }
}catch(err) {
    ls.config.secret = 6078564;
}

export function addToLocalStorage (key, value){
    ls.set(
        CryptoJS.HmacSHA1(key, "sdfgsdfg#@$23dsf" + fingerprint),
        value
    );
}

export function getFromLocalStorage (key){
    let value = ls.get(CryptoJS.HmacSHA1(key, "sdfgsdfg#@$23dsf" + fingerprint));
    return value;
}

export function deleteFromLocalStorage (key){
    let value = ls.remove(CryptoJS.HmacSHA1(key, "sdfgsdfg#@$23dsf" + fingerprint));

    return value;
}