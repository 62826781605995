import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function paymentSolutionsName(type) {
  const Text = text[getFromLocalStorage("lang")];
  if (Text.payment_solutions[type]) {
    return Text.payment_solutions[type];
  } else {
    return type;
  }
}
