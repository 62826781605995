import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function statusName(status) {
	const Text = text[getFromLocalStorage("lang")];
  if (Text.status[status]) {
    return Text.status[status];
  } else {
    return status;
  }
}
