import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function bsMerchantStatus(bs_status) {
	const Text = text[getFromLocalStorage("lang")];
  if (
    Text.bs_status &&
    Text.bs_status[bs_status]
  ) {
    return Text.bs_status[bs_status];
  } else {
    return bs_status;
  }
}
