import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function currencyName(currency) {
  const Text = text[getFromLocalStorage("lang")];
  if (Text && Text.currencies[currency]) {
    return Text.currencies[currency];
  }else {
    return currency;
  }
}
